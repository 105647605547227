import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import constants from "../../../constants/constants";
import "../../../assets/css/Project.css";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Controller, useForm } from "react-hook-form";
import { Toast } from "primereact/toast";
import getHeaders from "../../../controllers/utils";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { useStoreState } from "easy-peasy";

const AddProjects = () => {
  const history = useHistory();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clientList, setClientList] = useState();
  const [customerName, setCustomerName] = useState(null);
  const user = useStoreState((actions) => actions.loginModel.user); 
  let minDate = new Date();

  useEffect(() => {
      getClientData();
  }, []);

  const getClientData = () => {
      setIsLoading(true);
      axios
          .get(constants.APQP_UPL.CLIENT_REGISTRY + "?page=1&limit=1000&sort_by=-created_at", { headers: getHeaders() })
          .then((resp) => {
              setClientList(resp?.data?.data);
          })
          .catch((e) => console.error(e))
          .finally(() => {
              setIsLoading(false);
          });
  }

  const defaultValues = { projectName: "", startOfProduction: "", modelNumber: "", yearlyVolume: "", startDate: "", clientName: "", modelName: "", location: "", endDate: "" };
  const form = useForm({ defaultValues });
  const { reset, setValue } = form;
  const errors = form.formState.errors;
  const getFormErrorMessage = (name) => {
    return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
  };

  const onSubmit = (data) => {
    const payload = {
      projectName: data.projectName,
      startOfProduction: data.startOfProduction,
      modelNumber: data.modelNumber,
      yearlyVolume: data.yearlyVolume,
      startDate: data.startDate,
      client_id: data.clientName,
      modelName: data.modelName,
      location: data.location,
      endDate: data.endDate,
      projectCreatedBy: user?.user?._id,
    }
    setIsLoading(true);
    axios.post(constants.APQP_UPL.PROJECTS, payload, { headers: getHeaders() })
      .then((resp) => {
        // console.log(resp?.data?.data);
        toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully" });
        reset();
      }).catch((e) => {
        toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      })
  }

  console.log(customerName);
    
  useEffect(() => {
      if (customerName) {
        let customer_Name = clientList.find((item) => item._id === customerName)
          setValue("modelNumber", customer_Name?.modelNumber)
          setValue("modelName", customer_Name?.modelName)
          setValue("yearlyVolume", customer_Name?.yearlyVolume)
          setValue("location", customer_Name?.location)
      }
  }, [customerName]);

  const header = (
    <div className="head-1">
      <h2 className="head-2">Add Project</h2>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <Card header={header}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
              <div className="p-fluid formgrid grid lr-gap">
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="projectName">
                  Project Name<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="projectName"
                      control={form.control}
                      rules={{ required: "Project Name is required." }}
                      render={({ field, fieldState }) => (
                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("projectName")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="startOfProduction">
                  Start of Production(SOP)<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="startOfProduction"
                      control={form.control}
                      rules={{ required: "Start of Production(SOP) is required." }}
                      render={({ field, fieldState }) => (
                        <Calendar showIcon id={field.name} minDate={minDate} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("startOfProduction")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="clientName">
                  Client Name<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="clientName"
                      control={form.control}
                      rules={{ required: "Client Name is required." }}
                      render={({ field, fieldState }) => (
                        <Dropdown filter options={clientList} optionLabel="customerName" optionValue="_id" id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => {field.onChange(e.target.value); setCustomerName(e.target.value)}} />
                      )}
                    />
                    {getFormErrorMessage("clientName")}</span>
                </div>
                {/* <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="modelNumber">
                  Model Number<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="modelNumber"
                      control={form.control}
                      // rules={{ required: "Model Number is required" }}
                      render={({ field, fieldState }) => (
                        <InputText id={field.name} readOnly value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                      )}
                    />
                    {getFormErrorMessage("modelNumber")}</span>
                </div> */}
                {/* <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="modelName">
                  Model Name<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="modelName"
                      control={form.control}
                      // rules={{ required: "Model Name is required" }}
                      render={({ field, fieldState }) => (
                        <InputText readOnly id={field.name} value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("modelName")}</span>
                </div> */}
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="yearlyVolume">
                  Yearly Volume<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="yearlyVolume"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <InputText readOnly id={field.name} value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("yearlyVolume")}</span>
                </div>
                <div className="field col-12 md:col-6 lg:col-6">
                  <label className="col-6" htmlFor="location">
                  Location<span className="p-error">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="location"
                      control={form.control}
                      // rules={{ required: "Location is required" }}
                      render={({ field, fieldState }) => (
                        <InputText readOnly id={field.name} value={field.value} onChange={(e) => field.onChange(e.target.value)}
                        className={classNames({ "p-invalid": fieldState.error })} />
                      )}
                    />
                    {getFormErrorMessage("location")}</span>
                </div>
              </div>
                <div className="flex justify-content-end px-4">
                    <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                </div>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default AddProjects;
