import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import axios from "axios";
import constants from "../../../constants/constants";
import getHeaders from "../../../controllers/utils";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

const AddNewClients = () => {
    const [isLoading, setIsLoading] = useState(false);
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const employeeEditValue = useStoreState((actions) => actions.tabModel.employeeEditValue);
    const toast = useRef(null);
    const statusList = ["Active", "In Active"]

    useEffect(() => {
        if (employeeEditValue) {
            setValue("customerName", employeeEditValue?.customerName)
            setValue("customerID", employeeEditValue?.customerID)
            setValue("modelNumber", employeeEditValue?.modelNumber)
            setValue("modelName", employeeEditValue?.modelName)
            setValue("modecustomerID", employeeEditValue?.modecustomerID)
            setValue("location", employeeEditValue?.location)
            setValue("yearlyVolume", employeeEditValue?.yearlyVolume)
            setValue("value", employeeEditValue?.value)
            setValue("completeAddress", employeeEditValue?.completeAddress)
            setValue("partNumber", employeeEditValue?.partNumber)
            setValue("pocName", employeeEditValue?.pocName)
            setValue("pocNumber", employeeEditValue?.pocNumber)
            setValue("pocEmailID", employeeEditValue?.pocEmailID)
            setValue("status", employeeEditValue?.status !== true ? "Active" : "In Active")
        }
    }, [employeeEditValue]);

    const onSubmit = (data) => {
        // console.log(data);
        const payload = {
                customerName: data.customerName,
                customerID: data.customerID,
                modelNumber: data.modelNumber,
                modelName: data.modelName,
                modecustomerID: data.modecustomerID,
                location: data.location,
                yearlyVolume: data.yearlyVolume,
                value: data.value,
                completeAddress: data.completeAddress,
                partNumber: data.partNumber,
                pocName: data.pocName,
                pocNumber: data.pocNumber,
                pocEmailID: data.pocEmailID,
                status: data.status !== "Active" ? true : false 
        }

        setIsLoading(true);
        {
            !employeeEditValue ?
            axios.post(constants.APQP_UPL.CLIENT_REGISTRY, payload, { headers: getHeaders() })
                .then((resp) => {
                    // console.log(resp);
                    toast.current.show({ severity: "success", summary: "Success", detail: "Client Addded Successfully" });
                    reset();
                }).catch((e) => {
                    toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })
            :
            axios.patch(constants.APQP_UPL.CLIENT_REGISTRY + "/" + employeeEditValue?._id, payload, { headers: getHeaders() })
                .then((resp) => {
                    // console.log(resp);
                    toast.current.show({ severity: "success", summary: "Success", detail: "Client updated successfully" });
                    reset();
                    setActiveIndex(0);
                }).catch((e) => {
                    toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    };

    const defaultValues = { customerName: "", customerID: "", modelName: "", modelNumber: "", modecustomerID: "", location: "", yearlyVolume: "", value: "", completeAddress: "", partNumber: "", pocName: "", pocNumber: "", pocEmailID: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <>
            <Toast ref={toast} />

            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                <div className="card p-0">
                    <div className="card-title px-4 py-3">
                        <h4 className="card-heading mb-0">New Client Form</h4>
                    </div>
                    <div className="flex">
                    <div className="p-fluid formgrid grid p-4">
                        <div className="field col-12">
                            <label className="col-6" htmlFor="customerName">
                                Customer Name<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                                <Controller
                                    name="customerName"
                                    control={form.control}
                                    rules={{ required: "Customer Name is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("customerName")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6" htmlFor="customerID">
                                Customer ID<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                                <Controller
                                    name="customerID"
                                    control={form.control}
                                    rules={{ required: "Customer ID is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    )}
                                />
                                {getFormErrorMessage("customerID")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6" htmlFor="location">
                                Location<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="location"
                                    control={form.control}
                                    rules={{ required: "Location is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("location")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6" htmlFor="yearlyVolume">
                                Yearly Volume<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="yearlyVolume"
                                    control={form.control}
                                    rules={{ required:  "Yearly Volume is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("yearlyVolume")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6" htmlFor="value">
                                Value
                            </label><span className="w-full">
                                <Controller
                                    name="value"
                                    control={form.control}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("value")}</span>
                        </div>
                    </div>
                    <div className="p-fluid formgrid grid p-4">
                        <div className="field col-12 align-items-start">
                            <label className="col-6 lg:mt-3" htmlFor="completeAddress">
                                Complete Address
                            </label><span className="w-full">
                                <Controller
                                    name="completeAddress"
                                    control={form.control}
                                    // rules={{ required: "Complete Address is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputTextarea rows={5} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("completeAddress")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6" htmlFor="status">
                                Status<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="status"
                                    control={form.control}
                                    rules={{ required: "Status is required." }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown options={statusList} id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("status")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6" htmlFor="pocName">
                                POC Name<span className="p-error">*</span>
                            </label><span className="w-full">
                                <Controller
                                    name="pocName"
                                    control={form.control}
                                    rules={{required: "POC Name is required."}}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("pocName")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6" htmlFor="pocNumber">
                                POC Number
                            </label><span className="w-full">
                                <Controller
                                    name="pocNumber"
                                    control={form.control}
                                    // rules={{ required:  "City is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("pocNumber")}</span>
                        </div>
                        <div className="field col-12">
                            <label className="col-6" htmlFor="pocEmailID">
                            POC Email ID
                            </label><span className="w-full">
                                <Controller
                                    name="pocEmailID"
                                    control={form.control}rules={{
                                        // required: "POC Name is required.",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address.",
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    )}
                                />
                                {getFormErrorMessage("pocEmailID")}</span>
                        </div>
                    </div>
                    </div>
                    <div className="flex justify-content-end p-4 pt-0">
                        <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddNewClients;
