import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constants from '../../../constants/constants';
import "../../../assets/css/Project.css"
import { Button } from "primereact/button";
import getHeaders from '../../../controllers/utils';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AddProjects from './AddProjects';
import back from "../../../images/back.svg";
import { ProgressBar } from 'primereact/progressbar';
import start from '../../../images/projects/Play-Circle.svg';
import hold from '../../../images/projects/Pause-Circle.svg';
import reStart from '../../../images/projects/Refresh.svg';
import view from '../../../images/projects/Eye.svg';
import trash from '../../../images/projects/Trash.svg';
import ProjectDetails from './ProjectDetails';


const Projects = () => {
    const toast = useRef(null);
    const [records, setRecords] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [addProjects, setAddProjects] = useState(false);
    const projectViewScreen = useStoreState((actions) => actions.projectModel.projectViewScreen);
    const setProjectViewDatas = useStoreActions((actions) => actions.projectModel.setProjectViewDatas);
    const setProjectViewScreen = useStoreActions((actions) => actions.projectModel.setProjectViewScreen);

    useEffect(() => {
        setIsLoading(true);
        getAllProjectsData();
        setProjectViewScreen(false);
    }, []);

    useEffect(() => {
        getAllProjectsData();
    }, [!projectViewScreen]);

    const getAllProjectsData = () => {
        axios.get(constants.APQP_UPL.PROJECTS, { headers: getHeaders() })
            .then((resp) => {
                setRecords(resp?.data?.data);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handleView = (item) => {
        setProjectViewDatas(item);
        setProjectViewScreen(true);
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const handleStart = (item, value) => {
        let isValid = true;
        let invalidSubtasks = [];

        if (item?.projectStatus === "Open") {
            item.taskHeader_id.forEach(taskHeader => {
                taskHeader.subTasks.forEach(subTask => {
                    if (!subTask.startDate || !subTask.endDate) {
                        isValid = false;
                        invalidSubtasks.push(subTask.subTaskName);
                    }
                });
            });
        }

        if (!isValid) {
            toast.current.show({ severity: "error", summary: "Failure", detail: `Subtasks ${invalidSubtasks.join(', ')} require start and end dates` });
            return;
        }

        if (item?.taskHeader_id == 0) {
            toast.current.show({ severity: "error", summary: "Failure", detail: `There are no Task to start this project` });
            return;
        }
        
        const payload = {
            projectStatus: value,
        }
        setIsLoading(true);
        axios.patch(constants.APQP_UPL.PROJECTS + "/" + item?._id, payload, { headers: getHeaders() })
            .then((resp) => {
                getAllProjectsData();
                const msg = item?.projectStatus === "Open" ? "Started" : item?.projectStatus === "In-Progress" ? "Hold" : "Re-Started"
                toast.current.show({ severity: "success", summary: "Success", detail: `Project ${msg} Successfully`, life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handleTaskDelete = (item) => {
        setIsLoading(true);
        axios.delete(constants.APQP_UPL.PROJECTS + "/" + item?._id, { headers: getHeaders() })
            .then((resp) => {
                getAllProjectsData();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const user = useStoreState((actions) => actions.loginModel.user);

    const confirm = (item) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
        });
    };

    const customButtonTemplate = (item) => {
        return (
            <div className='flex justify-content-end gap-2'>
                {item?.projectStatus === "Open" ?
                    <img src={start} onClick={() => handleStart(item, "In-Progress")} className='hover-bg-color' />
                    : item?.projectStatus === "In-Progress" ?
                        <img src={hold} onClick={() => handleStart(item, "Hold")} className='hover-bg-color'/>
                        : item?.projectStatus === "Hold" ?
                            <img src={reStart} onClick={() => handleStart(item, "In-Progress")} className='hover-bg-color'/> : null
                }
                <img src={view} onClick={() => handleView(item)} className='hover-bg-color' />
                <img src={trash} onClick={() => confirm(item)} className='hover-bg-color' />
            </div>
        );
    };

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const findMinStartDate = (record) => {
        let minStartDate = null;

        // Iterate through each task within subTasks
        record.taskHeader_id.forEach(taskHeader => {
            taskHeader.subTasks.forEach(task => {
                // Convert startDate to Date object
                if (task.startDate) {
                    let startDate = new Date(task.startDate);
                    // Update minStartDate if it's null or the current startDate is earlier
                    if (!minStartDate || startDate < minStartDate) {
                        minStartDate = startDate;
                    }
                }
            });
        });

        return minStartDate;
    };

    const exportExcel = async () => {
        setIsLoading(true);
            try {
                const response = await axios.get(constants.URL.ALL_PROJECT_DOWNLOAD, {
                    headers: getHeaders(),
                    responseType: "blob",
                });                  
              const url = URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "All Project.xlsx");
        
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(url);
            } catch (error) {
              console.error("Error downloading file:", error);
            } finally {
                setIsLoading(false);
            };
    }

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            {!addProjects && !projectViewScreen ?
                <div>
                    <div className="flex align-items-center justify-content-between mt-2">
                        <div>
                        <h3 className="PS-title pt-2">All Project</h3>
                        </div>
                        <div>
                        <Button icon="pi pi-plus" className="btn-black" label="Project" onClick={() => setAddProjects(true)} />
                        <Button  className="btn-black mx-2" label="Download" onClick={exportExcel} />
                            </div>
                    </div>
                        {isLoading ? 
            <ProgressBar mode="indeterminate" style={{ height: '2px' }} className='my-3'></ProgressBar> : 
            <hr className='hr-line'></hr>}
                    <div className="grid mt-3">
                        <div className="col-12">
                            {records?.map((item) => {
                                const minStartDate = findMinStartDate(item);
                                return (
                                    <div className="card flex">
                                        <div className='col-2 PS-card-key'>
                                            <h4>Project Name</h4>
                                            <p>{item?.projectName}</p>
                                        </div>
                                        <div className='col-2 PS-card-key'>
                                            <h4>Client Name</h4>
                                            <p>{item?.client_id?.customerName}</p>
                                        </div>
                                        <div className='col-2 PS-card-key text-center'>
                                            <h4>Start Date</h4>
                                            <p>{formatDate(minStartDate)}</p>
                                        </div>
                                        <div className='col-2 PS-card-key text-center'>
                                            <h4>End Date</h4>
                                            <p>{formatDate(item?.projectEndDate)}</p>
                                        </div>
                                        <div className='col-2 PS-card-key text-center align-items-center justify-content-center flex flex-column'>
                                            <h4>Completed Status</h4>
                                            <p>{item?.completedPercentage !== undefined && item?.completedPercentage !== null
                                                ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(item.completedPercentage) + "%"
                                                : ""}</p>
                                            <ProgressBar showValue={false} value={item?.completedPercentage} className='prog_bar' pt={{ value: { style: { background: item?.completedPercentage <= 33.33 ? 'yellow' : item?.completedPercentage <= 66.66 ? 'orange': item?.completedPercentage <= "100" ? 'green' : '' } } }}></ProgressBar>
                                        </div>
                                        <div className='col-2 PS-card-key'>
                                            <div>{customButtonTemplate(item)}</div>
                                        </div>
                                    </div>
                                )
                            })}
                            {records && records?.length === 0 ? "No Data found" : null}
                        </div>
                    </div>
                </div>
                :
                addProjects && !projectViewScreen ?
                    <div>
                        <div className="flex align-items-center mt-2 cursor-pointer" onClick={() => { setAddProjects(false); getAllProjectsData() }}>
                            <img src={back} className="back-logo mr-2" />
                            <h3 className="PS-title m-0">All Project / Add Project</h3>
                        </div>
                        <hr className="hr-line"></hr>
                        <AddProjects />
                    </div>
                    :
                    <div>
                        <ProjectDetails />
                    </div>
            }
        </div>
    )
}
export default Projects